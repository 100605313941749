import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {getFeatureToggle} from "../store/feature-toggles/feature-toggles.selectors";
import {filter, map, take} from "rxjs";
import {AvailableToggle, IFeatureToggle} from "../../shared/model/feature-toggle.model";

@Injectable({ providedIn: 'root' })
export class FeatureToggleService {

  constructor(private store: Store) {
  }

  public isToggleActive(id: AvailableToggle) {
    return this.store.select(getFeatureToggle(id)).pipe(filter(Boolean), take(1), map((featureToggle: IFeatureToggle) => featureToggle.enabled));
  }
}
