export interface IFeatureToggle {
  id: string;
  description: string;
  enabled: boolean;
}

export enum AvailableToggle {
  PAST_REGISTRATION = "1",
  OPPORTUNITY_REQUESTS= "2",
}
